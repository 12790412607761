<template>
	<div class="home">
		<trial-balance-request></trial-balance-request>
	</div>
</template>

<script>
import trialBalance from '@/components/pages/report/trialbalance/trialBalanceRequest.vue';

export default {
	components: {
		'trial-balance-request': trialBalance,
	}
};
</script>

<style>

</style>
